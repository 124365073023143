import React from 'react'
import { Link } from 'react-router-dom'

const NotFoundPage = () => {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <Link to="/">스마일 서비스 드라이버</Link>
      <span className="ml-2">404</span>
    </div>
  )
}

export default NotFoundPage
